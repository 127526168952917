@import "bootstrap";

.progress {
  border: 1px solid Maroon;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &__bar {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: Goldenrod;
  }
  &__text {
    color: chocolate;
    position: relative;
  }
  &__cancel {
    position: absolute;
    background-color: transparent;
    right: 5px;
    color: Maroon;
  }
}

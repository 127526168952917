@import "bootstrap";

.folder {
  @include monospace_font;
  font-size: 16px;
  line-height: 1.3em;
  &__icon {
    margin-right: 0.25em;
    position: relative;
    top: 2px;
  }
  &__name {
    color: black;
    background-color: transparent;
    &:hover {
      color: SaddleBrown;
    }
  }
}

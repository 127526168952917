$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;

@mixin border_radius($radius: 0.125em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($opacity: 0.5, $important: false, $use_filter: true) {
  @if $important {
    -moz-opacity: $opacity !important;
    -khtml-opacity: $opacity !important;
    -webkit-opacity: $opacity !important;
    opacity: $opacity !important;
  } @else {
    -moz-opacity: $opacity;
    -khtml-opacity: $opacity;
    -webkit-opacity: $opacity;
    opacity: $opacity;
  }
}

@mixin boxsizing($box-model: border-box) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-behavior: url(/css/backgroundsize.min.htc);
}

@mixin contain {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-background-size: contain;
  -o-background-size: contain;
  -moz-background-size: contain;
  -ms-behavior: url(/css/backgroundsize.min.htc);
}

@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: block;
    height: 0;
    overflow: hidden;
  }
  &:after {
    clear: both;
  }
}

@mixin no_clearfix {
  &:before,
  &:after {
    content: "";
    display: inline;
  }
  &:after {
    clear: none;
  }
}

@function get_next_breakpoint($width) {
  @if $width == xs {
    @return sm;
  }
  @if $width == sm {
    @return md;
  }
  @if $width == md {
    @return lg;
  }
  @return null;
}

@function get_min_width($width) {
  @if $width == xs {
    @return null;
  }
  @if $width == sm {
    @return $screen-sm-min;
  }
  @if $width == md {
    @return $screen-md-min;
  }
  @if $width == lg {
    @return $screen-lg-min;
  }
  @if type_of($width) == number {
    @return $width;
  }
  @return null;
}

@function get_max_width($width) {
  @if $width == xs {
    @return $screen-sm-min - 1px;
  }
  @if $width == sm {
    @return $screen-md-min - 1px;
  }
  @if $width == md {
    @return $screen-lg-min - 1px;
  }
  @return null;
}

@mixin in_size_bounds($min_width, $max_width: null) {
  @if $min_width != null {
    @if $max_width != null {
      @media (min-width: $min_width) and (max-width: $max_width) {
        @content;
      }
    } @else {
      @media (min-width: $min_width) {
        @content;
      }
    }
  } @else {
    @if $max_width != null {
      @media (max-width: $max_width) {
        @content;
      }
    }
  }
}

@mixin in_size($widths) {
  $loop_each: true;
  @if length($widths) > 1 {
    $loop_each: false;
    $prev_w: 1;
    @for $w from 2 to length($widths) + 1 {
      $prev_breakpoint: nth($widths, $prev_w);
      $this_breakpoint: nth($widths, $w);
      @if get_next_breakpoint($prev_breakpoint) != $this_breakpoint {
        $loop_each: true;
        $this_min: get_min_width($this_breakpoint);
        $prev_min: get_min_width($prev_breakpoint);
        @if (
          $this_min == null or ($prev_min != null and ($this_min < $prev_min))
        ) {
          @if length($widths) == 2 {
            @warn "@include in_size((" + $widths + ")) should be @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + "))";
          } @else {
            @if $prev_w == 1 {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + ", ...))";
            } @else {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((..., " + $this_breakpoint + ", " + $prev_breakpoint + "))";
            }
          }
        }
      }
      $prev_w: $w;
    }
    @if $loop_each == false {
      @include in_size_bounds(
        get_min_width(nth($widths, 1)),
        get_max_width(nth($widths, length($widths)))
      ) {
        @content;
      }
    }
  }
  @if $loop_each {
    @each $width in $widths {
      @include in_size_bounds(get_min_width($width), get_max_width($width)) {
        @content;
      }
    }
  }
}

@mixin set_for_sizes($prop, $xs: null, $sm: null, $md: null, $lg: null) {
  @if $xs != null {
    @include in_size(xs) {
      #{$prop}: $xs;
    }
  }
  @if $sm != null {
    @include in_size(sm) {
      #{$prop}: $sm;
    }
  }
  @if $md != null {
    @include in_size(md) {
      #{$prop}: $md;
    }
  }
  @if $lg != null {
    @include in_size(lg) {
      #{$prop}: $lg;
    }
  }
}

@mixin font-face {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin box_shadow($x: 0, $y: 0.1em, $blur: 0.2em, $colour: rgba(0, 0, 0, 0.1)) {
  -webkit-box-shadow: $x $y $blur $colour;
  -moz-box-shadow: $x $y $blur $colour;
  box-shadow: $x $y $blur $colour;
}

@import "bootstrap";

.file {
  @include monospace_font;
  font-size: 16px;
  line-height: 1.3em;
  margin-bottom: 5px;
  &__downloading {
    color: Silver;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &__size {
    color: lightgray;
    margin-left: 1em;
    flex-basis: 10%;
    text-align: right;
  }
  &__download {
    flex-basis: 50%;
  }
  &__link {
    text-align: left;
    background-color: transparent;
  }
}

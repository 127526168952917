@import "bootstrap";

.breadcrumb {
  @include text_font;
  font-size: 16px;
  line-height: 1.3em;
  &__wrap {
    display: flex;
    flex-direction: row;
  }
  &__text {
    margin-right: 0.5em;
    color: SteelBlue;
  }
  &__item {
    @include monospace_font;
    + .breadcrumb__item {
      &:before {
        @include text_font;
        content: ">";
        color: SteelBlue;
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }
  &__link {
    background-color: transparent;
  }
}

%cf {
  @include clearfix;
}
%container {
  @include container-fixed();
}
@mixin container {
  @extend %container;
}
@mixin cover_screen($container, $bgcolour: #ddd) {
  .#{$container} {
    @include cover;
    min-width: 100%;
    min-width: 100vw;
    min-height: 100%;
    min-height: 100vh;
    background-color: $bgcolour;
    @include in_size(xs) {
      background-position: top left;
    }
  }
}
@mixin text_block($wrapper, $inner, $padding_x: 20px, $padding_y: 20px) {
  .#{$wrapper} {
    color: white;
    background: #808080;
    background: rgba(0, 0, 0, 0.4);
    padding: 20px $gutter;
    @include in_size((md, lg)) {
      background-color: transparent;
      float: right;
      height: 100vh;
      max-width: 50%;
      display: table;
    }
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        color: #d0d0d0;
      }
    }
  }
  .#{$inner} {
    > * {
      padding: 0 0 $padding_y;
      margin: 0;
    }
    @include in_size((md, lg)) {
      display: table-cell;
      vertical-align: middle;
      > * {
        padding: $padding_x $padding_y;
        background: #808080;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

@import "bootstrap";

.header {
  background: black;
  padding: 20px 0;
  &__wrap {
    @include container;
  }
  &__contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    @include heading_font;
    color: white;
    font-size: 32px;
    line-height: 1em;
  }
  &__logout {
    text-decoration: none;
    text-transform: lowercase;
    @include heading_font;
    font-size: 14px;
    line-height: 1em;
    color: lightslategray;
  }
}

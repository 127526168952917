.page404 {
  &__title {
    font-size: 32px;
    line-height: 45px;
  }
  &__text {
    font-size: 18px;
    line-height: 25px;
  }
}

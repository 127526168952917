@import "bootstrap";

@mixin colours($colour) {
  background-color: lighten($colour, 40%);
  border: 1px solid darken($colour, 10%);
  color: $colour;
}

.callout {
  padding: 15px;
  margin-bottom: 2em;
  @include colours($blue);
  @include text_font;
  position: relative;
  box-shadow: 0 9px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &--error {
    @include colours(crimson);
  }
  &--warning {
    @include colours(darkorange);
  }
  &--info {
    @include colours(steelblue);
  }
  &--success {
    @include colours(forestgreen);
  }
}

//$font-family-sans-serif:open_sansregular,sans-serif;
$font-family-sans-serif:'Open Sans',sans-serif;
$font-size-base:          16px;
$font-size-large:         18px;
$font-size-small:         12px;

$font-size-h1:            36px;
$font-size-h2:            30px;
$font-size-h3:            24px;
$font-size-h4:            18px;
$font-size-h5:            $font-size-base !default;
$font-size-h6:            12px;
$nav-link-hover-bg: transparent;

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
//@import "bootstrap/normalize";
//@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
//@import "bootstrap/scaffolding";
//@import "bootstrap/type";
//@import "bootstrap/code";
//@import "bootstrap/grid";
//@import "bootstrap/tables";
//@import "bootstrap/forms";
//@import "bootstrap/buttons";

// Components
//@import "bootstrap/component-animations";
//@import "bootstrap/dropdowns";
//@import "bootstrap/button-groups";
//@import "bootstrap/input-groups";
//@import "bootstrap/navs";
//@import "bootstrap/navbar";
//@import "bootstrap/breadcrumbs";
//@import "bootstrap/pagination";
//@import "bootstrap/pager";
//@import "bootstrap/labels";
//@import "bootstrap/badges";
//@import "bootstrap/jumbotron";
//@import "bootstrap/thumbnails";
//@import "bootstrap/alerts";
//@import "bootstrap/progress-bars";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
//@import "bootstrap/panels";
//@import "bootstrap/responsive-embed";
//@import "bootstrap/wells";
//@import "bootstrap/close";

// Components w/ JavaScript
//@import "bootstrap/modals";
//@import "bootstrap/tooltip";
//@import "bootstrap/popovers";
//@import "bootstrap/carousel";

// Utility classes
//@import "bootstrap/utilities";
//@import "bootstrap/responsive-utilities";

$gutter: $grid-gutter-width/2;

@function get_next_breakpoint($width) {
  @if $width == xs {
    @return sm;
  }
  @if $width == sm {
    @return md;
  }
  @if $width == md {
    @return lg;
  }
  @return null;
}

@function get_min_width($width) {
  @if $width == xs {
    @return null;
  }
  @if $width == sm {
    @return $screen-sm-min;
  }
  @if $width == md {
    @return $screen-md-min;
  }
  @if $width == lg {
    @return $screen-lg-min;
  }
  @if type_of($width) == number {
    @return $width;
  }
  @return null;
}

@function get_max_width($width) {
  @if $width == xs {
    @return $screen-sm-min - 1px;
  }
  @if $width == sm {
    @return $screen-md-min - 1px;
  }
  @if $width == md {
    @return $screen-lg-min - 1px;
  }
  @return null;
}

@mixin in_size_bounds($min_width, $max_width : null) {
  @if $min_width != null {
    @if $max_width != null {
      @media (min-width: $min_width) and (max-width: $max_width) {
        @content;
      }
    } @else {
      @media (min-width: $min_width) {
        @content;
      }
    }
  } @else {
    @if $max_width != null {
      @media (max-width: $max_width) {
        @content;
      }
    }
  }
}

@mixin in_size($widths) {
  $loop_each: true;
  @if length($widths) > 1 {
    $loop_each: false;
    $prev_w: 1;
    @for $w from 2 to length($widths) + 1 {
      $prev_breakpoint: nth($widths, $prev_w);
      $this_breakpoint: nth($widths, $w);
      @if get_next_breakpoint($prev_breakpoint) != $this_breakpoint {
        $loop_each: true;
        $this_min: get_min_width($this_breakpoint);
        $prev_min: get_min_width($prev_breakpoint);
        @if ( $this_min == null or ($prev_min != null and ($this_min < $prev_min)) ) {
          @if length($widths) == 2 {
            @warn "@include in_size((" + $widths + ")) should be @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + "))";
          } @else {
            @if $prev_w == 1 {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((" + $this_breakpoint + ", " + $prev_breakpoint + ", ...))";
            } @else {
              @warn "@include in_size((" + $widths + ")) should be re-ordered to @include in_size((..., " + $this_breakpoint + ", " + $prev_breakpoint + "))";
            }
          }
        }
      }
      $prev_w: $w;
    }
    @if $loop_each == false {
      @include in_size_bounds(get_min_width(nth($widths, 1)), get_max_width(nth($widths, length($widths)))) {
        @content;
      }
    }
  }
  @if $loop_each {
    @each $width in $widths {
      @include in_size_bounds(get_min_width($width), get_max_width($width)) {
        @content;
      }
    }
  }
}

@mixin set_for_sizes($prop, $xs: null, $sm: null, $md: null, $lg: null) {
  @if $xs != null {
    @include in_size(xs) {
      #{$prop}: $xs;
    }
  }
  @if $sm != null {
    @include in_size(sm) {
      #{$prop}: $sm;
    }
  }
  @if $md != null {
    @include in_size(md) {
      #{$prop}: $md;
    }
  }
  @if $lg != null {
    @include in_size(lg) {
      #{$prop}: $lg;
    }
  }
}

@mixin in_sizes($property, $lg, $md:null, $sm:null, $xs:null) {
  #{$property}:$lg;
  @if ($lg) {
    $prop:lg;
    @if ($md==null) {
      $prop:(md,lg);
      @if ($sm==null) {
        $prop:(sm,md,lg);
        @if ($xs==null) {
          $prop:(xs,sm,md,lg);
        }
      }
    }
    @include in_size($prop) {
      #{$property}:$lg;
    }
  }
  @if ($md) {
    $prop:md;
    @if ($sm==null) {
      $prop:(sm,md);
      @if ($xs==null) {
        $prop:(xs,sm,md);
      }
    }
    @include in_size($prop) {
      #{$property}:$md;
    }
  }
  @if ($sm) {
    $prop_sm:sm;
    @if ($xs==null) {
      $prop_sm:(xs,sm);
    }
    @include in_size($prop_sm) {
      #{$property}:$sm;
    }
  }
  @if ($xs) {
    @include in_size(xs) {
      #{$property}:$xs;
    }
  }
}

@mixin container {
  @include container-fixed();
  @include in_size(sm) {
    width: $container-sm;
  }
  @include in_size(md) {
    width: $container-md;
  }
  @include in_size(lg) {
    width: $container-lg;
  }
}
@mixin container_small {
  @include container-fixed();
  @include in_size(sm) {
    width: $container-sm;
  }
  @include in_size((md, lg)) {
    width: $container-md;
  }
}
@mixin nocontainer {
  padding-left:0;
  padding-right:0;
  width:auto;
}

%container {
	@include container;
}

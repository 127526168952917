

@font-face {
   font-family:"icons";
   src:url("/fonts/icons.eot?fb4972957ca4f44de38291764573f2c3");
   font-weight:normal;
   font-style:normal;
}
@font-face {
   font-family:"icons";
      src:url("/fonts/icons.eot?fb4972957ca4f44de38291764573f2c3");
   src:url("/fonts/icons.eot?#iefix") format("embedded-opentype"),
		url("/fonts/icons.woff?fb4972957ca4f44de38291764573f2c3") format("woff"),
		url("/fonts/icons.ttf?fb4972957ca4f44de38291764573f2c3") format("truetype");
   font-weight:normal;
   font-style:normal;
}


$icons: (
   angle-down: "\f101",

   angle-left: "\f102",

   angle-right: "\f103",

   angle-up: "\f104",

   basket: "\f105",

   cart2: "\f106",

   check: "\f107",

   chevron-down: "\f108",

   chevron-left: "\f109",

   chevron-right: "\f10a",

   chevron-up: "\f10b",

   circle-o: "\f10c",

   circle: "\f10d",

   credit-card: "\f10e",

   exclamation-triangle: "\f10f",

   facebook: "\f110",

   home: "\f111",

   info-circle: "\f112",

   menu: "\f113",

   phone: "\f114",

   print: "\f115",

   search: "\f116",

   star: "\f117",

   times: "\f118",

   truck: "\f119",

   users: "\f11a",
);


@mixin icon($icon: false, $position: before, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    &:#{$position} {
        @if $icon {
            content: "#{map-get($icons, $icon)}";
        }
        @if $styles {
            font-family: 'icons';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

        }
  @content;
}
}
@import "../common";

@include cover_screen(homepage);
@include text_block(homepage__contents, homepage__article);

.homepage {
  @extend %container;
  background-image: url(/images/me.jpg);

  &__heading {
    font-size: 32px;
    line-height: 45px;
  }
  &__subtitle {
    font-size: 24px;
    line-height: 35px;
  }
  &__contact {
    font-size: 16px;
    line-height: 21px;
  }
  &__article {
    > * {
      padding: 0 0 10px;
      margin: 0;
    }
    @include in_size((md, lg)) {
      display: table-cell;
      vertical-align: middle;
      > * {
        padding: 20px;
        background: #808080;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  &__list-heading {
    color: #0acece;
  }
  &__list {
    margin-top: 1em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__small {
    display: block;
    float: right;
    font-size: 11px;
    line-height: 21px;
  }
  &__item {
    @include icon(angle-right);
    position: relative;
  }
  &__article > .homepage {
    &__aside {
      margin-top: 2em;
    }
  }
}

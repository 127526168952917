$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-heading: "Montserrat", Arial, sans-serif;

@mixin monospace_font {
  font-family: "Fira Mono", monospace;
}
@mixin heading_font {
  font-family: "Hepta Slab", serif;
}
@mixin text_font {
  font-family: "Nunito", sans-serif;
}

$blue: #2350a2;

$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 12px;

$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: $font-size-base !default;
$font-size-h6: 12px;
$nav-link-hover-bg: transparent;

$screen-lg: 1230px;
$container-large-desktop: 1230px;

// Core variables and mixins
@import "reset";
@import "../scss/bootstrap/variables";
@import "../scss/bootstrap/mixins";

$gutter: $grid-gutter-width/2;

@import "bootstrap_xl";

@import "../common";

.code-form {
  @extend %container;
  margin-top: 20px;

  &__button {
    margin-top: 20px;
    display: inline-block;
    padding: 10px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    .button--disabled {
      opacity: 1;
    }
  }
}

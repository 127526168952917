@import "../../common";

.seats-list {
  @extend %container;

  &__header {
    font-size: 32px;
    line-height: 45px;
    color: teal;
  }

  &__table {
    border-collapse: collapse;
  }

  &__head {
    padding: 4px 8px;
    text-align: left;
    &:first-child {
      padding-left: 0;
    }
  }

  &__cell-span {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 19px;
    padding: 4px;
    color: grey;
    border: 1px solid white;
  }

  &__cell {
    padding: 2px 8px;
    width: 160px;
    border: 3px solid white;
    &:first-child {
      padding-left: 0;
    }
    .field {
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 19px;
      padding: 4px;
      border: 1px solid lightgray;
      color: black;
    }
    .field__text {
      padding: 0;
      border: none;
      width: 120px;
    }
  }

  &__button {
    padding: 4px 8px;
  }
}

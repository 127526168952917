@import "../common";

@include cover_screen(mailservers);

.mailservers {
  @include container;
  background-image: url(/images/mailservers.jpg);

  &__title {
    font-size: 32px;
    line-height: 45px;
    color: wheat;
  }
}

.serve-files {
  background: linear-gradient(
    to bottom,
    #ccc7b9,
    #eaf9d9,
    #e2d4ba,
    #af7a6d,
    #653239
  );
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 1em;
}

.serve-files:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.2),
    #21d4fd 75%
  );
}

.serve-files__content {
  background-color: #fff;
  padding: 0.75em 1em;
  border-radius: 1em;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.2);
}

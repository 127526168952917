@import "bootstrap";

.app {
  &__body {
    @include container;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__text {
    @include text_font;
    &--error {
      color: crimson;
    }
  }
  &__button {
    @include text_font;
    padding: 8px 15px;
    color: white;
    background-color: $blue;
    &:hover {
      background-color: darken($blue, 10%);
    }
  }
  &__controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
